import './App.css';
import GetData from './GetData';
import GetDataBrands from './GetDataBrands';
import GetDataHotels from './GetDataHotels';

function App() {
  return (
    <div className="App">
        <h1>Loading data from API - Hotels</h1>
        {/* <GetData /> */}
        <GetDataHotels />
        <GetDataBrands />
    </div>
  );
}

export default App;
