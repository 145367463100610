import React,{ useState, useEffect} from 'react';
import axios from 'axios';
 
function GetDataHotels() {
    const  [hotelList, setHotelList] = useState([])
  
    useEffect(() => {
      fetchHotelList()
    }, [])
  
    const fetchHotelList = () => {
      axios.get('https://symfony1.juanjosemora.es/api/hotels')
        .then(function (response) {
          setHotelList(response.data["hydra:member"]);
        })
        .catch(function (error) {
          console.log(error);
        })
    }
  
  
    return (
           <div className="container">
              <div className="card">
                  <h2 className="text-center mt-5 mb-3">Hotel list</h2>
                    <div className="card-header">
                    </div>
                    <div className="card-body">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>City</th>
                                </tr>
                            </thead>
                            <tbody>
                                {hotelList.map((hotel, key)=>{
                                    return (
                                        <tr key={hotel.id}>
                                            <td>{hotel.name}</td>
                                            <td>{hotel.city}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
    );
}
  
export default GetDataHotels;